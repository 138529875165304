import React, { useState, useEffect } from "react";
import "./App.css";
import logo from "./logo.png"; // Logo için
import weddiApp from "./weddi_app.png"; // İlk uygulama ikonu
import refinery from "./refinery.png"; // İkinci uygulama ikonu
import modipic from "./modipic.png"; // Üçüncü uygulama ikonu
import diress from "./diress.png"; // Dördüncü uygulama ikonu
import separai from "./separai.png"; // Beşinci uygulama ikonu

function App() {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const handleLoad = () => setLoading(false);
    if (document.readyState === "complete") handleLoad();
    else window.addEventListener("load", handleLoad);
    return () => window.removeEventListener("load", handleLoad);
  }, []);

  const currentYear = new Date().getFullYear();

  // URL kontrolü
  const currentPath = window.location.pathname.toLowerCase();
  const isTerms = currentPath.includes("terms");
  const isPrivacy = currentPath.includes("privacy-policy");

  if (loading) {
    return (
      <div className="loading-screen">
        <div className="loading-text">
          Loading assets<span className="dot">.</span>
          <span className="dot">.</span>
          <span className="dot">.</span>
        </div>
      </div>
    );
  }

  if (isTerms) {
    return (
      <div className="pdf-container">
        <iframe
          src="https://egpfenrpripkjpemjxtg.supabase.co/storage/v1/object/public/zips/terms_of_service.pdf"
          className="pdf-viewer"
          title="Terms of Service"
        ></iframe>
      </div>
    );
  }

  if (isPrivacy) {
    return (
      <div className="pdf-container">
        <iframe
          src="https://egpfenrpripkjpemjxtg.supabase.co/storage/v1/object/public/zips/privacy.pdf"
          className="pdf-viewer"
          title="Privacy Policy"
        ></iframe>
      </div>
    );
  }

  return (
    <div className="container">
      <video autoPlay muted loop className="background-video">
        <source src="https://un.ms//assets/pile/sky.mp4" type="video/mp4" />
        Your browser does not support the video tag.
      </video>

      <h2 className="app-title">
        Our
        <br />
        Applications
      </h2>
      <header className="app-header">
        <div className="apps">
          <div className="app">
            <div className="badge  live-badge">Live</div>
            <img src={refinery} alt="Refinery" className="app-icon refinery" />
            <p>Refinery</p>
          </div>
          <div className="app">
            <div className="badge soon-badge">Soon</div>
            <img src={weddiApp} alt="Weddi App" className="app-icon" />
            <p>CoupleS.</p>
          </div>
          <div className="app">
            <div className="badge live-badge">Live</div>
            <img src={diress} alt="Diress App" className="app-icon" />
            <p>Diress</p>
          </div>
          <div className="app">
            <div className="badge soon-badge">Soon</div>
            <img src={modipic} alt="Modipic" className="app-icon" />
            <p>Modipic</p>
          </div>
          <div className="app">
            <div className="badge soon-badge">Soon</div>
            <img src={separai} alt="Separai" className="app-icon" />
            <p>Separai</p>
          </div>
        </div>
      </header>

      <img src={logo} alt="Logo" className="bottom-logo" />

      <footer>
        <p>
          © {currentYear} Monailisa. All rights reserved |{" "}
          <a href="mailto:skozayy@gmail.com" className="contact-link">
            Contact
          </a>
        </p>
      </footer>
    </div>
  );
}

export default App;
